import { FC } from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'src/store/Store';
import { ThemeSettings } from './theme/Theme';
import RTL from './layouts/full/shared/customizer/RTL';
import ScrollToTop from './components/shared/ScrollToTop';
import Router from './routes/Router';
import { AppState } from './store/Store';
import { AuthProvider } from './contexts/auth-context';

const App: FC = () => {
    const routing = useRoutes(Router);
    const theme = ThemeSettings();
    const customizer = useSelector((state: AppState) => state.customizer);

    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <RTL direction={customizer.activeDir}>
                    <CssBaseline />
                    <ScrollToTop>{routing}</ScrollToTop>
                </RTL>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
