import API_INFO from './api.constant';
import useGenericCommonApi from './generic-common-api';
import { PointOfSale } from './point-of-sales-api';
import { Audit, ID } from './types';
import { User } from './user-api';
import axios, { AxiosError } from 'axios';

export const APP_USERS_URL = `${API_INFO.BASE_URL}/app-users`;

export interface AppUser extends Audit {
    id: ID;
    address: string;
    phoneNumber: string;
    city: string;
    user: User;
    pointOfSale?: PointOfSale;
}

export default function useAppUserApi() {
    const baseOperations = useGenericCommonApi<AppUser>(APP_USERS_URL);

    return {
        ...baseOperations,
        getAuthenticated: async (cb?: (value?: AppUser) => void) => {
            const response = await axios
                .get<AppUser>(`${APP_USERS_URL}/logged`)
                .catch((error: AxiosError) => {
                    console.error('Failed to get element from DB.', error);
                });

            if (cb) {
                cb(response?.data);
            }

            return response?.data;
        },
    };
}
