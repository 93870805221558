import { Alert, AlertTitle, Snackbar } from '@mui/material';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { AlertColor } from '@mui/material/Alert/Alert';

export interface IAlertContext {
    showAlert: (title: string, message: string, alertType?: AlertColor) => void;
    hideAlert: () => void;
}

const AlertContext = createContext<IAlertContext>({
    showAlert: (title: string, message: string, alertType?: AlertColor) => {
        ('');
    },
    hideAlert: () => {
        ('');
    },
});

export function AlertProvider({ children }: { children: ReactNode }) {
    const [open, setOpen] = useState(false);
    const [type, setType] = useState<AlertColor>('success');
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');

    const showAlert = (title: string, message: string, alertType?: AlertColor) => {
        setType(alertType ?? 'success');
        setTitle(title);
        setMessage(message);
        setOpen(true);
    };

    const hideAlert = () => {
        setOpen(false);
        setType('success');
        setTitle('');
        setMessage('');
    };

    return (
        <AlertContext.Provider value={{ showAlert: showAlert, hideAlert }}>
            {children}
            <Snackbar
                open={open}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={5000}
                onClose={hideAlert}
                style={{ minWidth: '200px' }}
            >
                <Alert
                    severity={type}
                    variant="filled"
                    sx={{ width: '100%', color: 'white' }}
                    onClose={hideAlert}
                >
                    <AlertTitle>{title}</AlertTitle>
                    {message}
                </Alert>
            </Snackbar>
        </AlertContext.Provider>
    );
}

export function useAlert() {
    return useContext(AlertContext);
}
