import React, { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from '../layouts/full/shared/loadable/Loadable';
import { ProtectedRoute } from './ProtectedRoute';

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import('../layouts/full/FullLayout')));
const BlankLayout = Loadable(lazy(() => import('../layouts/blank/BlankLayout')));

// Account
const Login = Loadable(lazy(() => import('../views/authentication/auth2/Login')));
const ForgotPassword = Loadable(lazy(() => import('../views/authentication/auth1/ForgotPassword')));
const Error = Loadable(lazy(() => import('../views/authentication/Error')));

//
const Maintenance = Loadable(lazy(() => import('../views/authentication/Maintenance')));

// Dashboard menu pages
const Dashboard = Loadable(lazy(() => import('../views/dashboard/Dashboard')));
const Tickets = Loadable(lazy(() => import('../views/tickets/Tickets')));
const Courses = Loadable(lazy(() => import('../views/courses/Courses')));
const BusControls = Loadable(lazy(() => import('../views/bus-controls/BusControls')));
const Clients = Loadable(lazy(() => import('../views/clients/Clients')));
const PointOfSales = Loadable(lazy(() => import('../views/point-of-sales/PointOfSales')));
const BusDriverCalendar = Loadable(
    lazy(() => import('../views/bus-driver-calendar/BusDriverCalendar')),
);
const StopPoints = Loadable(lazy(() => import('../views/stop-points/StopPoints')));
const StopDistances = Loadable(lazy(() => import('../views/stop-distances/StopDistances')));
const Buses = Loadable(lazy(() => import('../views/buses/Buses')));
const Users = Loadable(lazy(() => import('../views/users/Users')));

const Router = [
    {
        path: '/',
        element: <FullLayout />,
        children: [
            { path: '/', element: <Navigate to="/dashboard" /> },
            {
                path: '/dashboard',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN']}>
                        <Dashboard />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/tickets',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER']}>
                        <Tickets />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/clients',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER']}>
                        <Clients />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/courses',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER', 'ROLE_SELLER']}>
                        <Courses />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/bus-controls',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <BusControls />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/stop-points',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <StopPoints />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/stop-distances',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <StopDistances />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/buses',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <Buses />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/point-of-sales',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <PointOfSales />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/bus-driver-calendar',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <BusDriverCalendar />
                    </ProtectedRoute>
                ),
            },
            {
                path: '/users',
                exact: true,
                element: (
                    <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_MANAGER']}>
                        <Users />
                    </ProtectedRoute>
                ),
            },
            { path: '*', element: <Navigate to="/auth/404" /> },
        ],
    },
    {
        path: '/',
        element: <BlankLayout />,
        children: [
            { path: '/auth/404', element: <Error /> },
            { path: '/auth/login', element: <Login /> },
            { path: '/auth/forgot-password', element: <ForgotPassword /> },
            { path: '/auth/maintenance', element: <Maintenance /> },
            { path: '*', element: <Navigate to="/auth/404" /> },
        ],
    },
];

export default Router;
