import axios, { AxiosError } from 'axios';
import { AuthToken, LoginForm } from './types';
import API_INFO from './api.constant';

const path = `${API_INFO.BASE_URL}/authenticate`;

const authenticate = async (data: LoginForm) => {
    return await axios
        .post<AuthToken>(path, data)
        .then((response) => {
            return response.data;
        })
        .catch((exception: AxiosError) => {
            console.error('API authentication failed !', exception);
        });
};

export { authenticate };
