import axios, { AxiosError } from 'axios';
import { useAlert } from '../components/alert/AlertContext';
import API_INFO from './api.constant';

export const ACCOUNT_API = `${API_INFO.BASE_URL}/account`;

export interface KeyAndPassword {
    key: string;
    newPassword: string;
}

/**
 * Provides method to interact with user account through API.
 */
export default function useAccountApi() {
    const { showAlert } = useAlert();

    return {
        sendResetMail: async (mail: string) => {
            const headers = {
                'Content-Type': 'text/html; charset=utf-8',
            };
            const response = await axios
                .post(`${ACCOUNT_API}/reset-password/init`, mail, {
                    headers: headers,
                })
                .catch((error: AxiosError) => {
                    console.error('Failed to get element from DB.', error);
                    showAlert(
                        'Erreur',
                        "Echec d'envoi du mail. Si l'erreur persiste contacter l'administrateur.",
                        'error',
                    );
                });
        },

        changePassword: async (data: KeyAndPassword) => {
            const response = await axios
                .post(`${ACCOUNT_API}/reset-password/finish`, data)
                .catch((error: AxiosError) => {
                    console.error('Failed to get element from DB.', error);
                    showAlert(
                        'Erreur',
                        "Mot de passe non mise à jour. Si l'erreur persiste contacter l'administrateur.",
                        'error',
                    );
                });
        },
    };
}
