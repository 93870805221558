import axios, { AxiosError, AxiosResponse } from 'axios';
import { useAlert } from 'src/components/alert/AlertContext';
import { ID } from './types';

export interface PaginatedResponse<T> {
    totalItems: number;
    prevUrl?: string;
    nextUrl?: string;
    lastUrl?: string;
    items: T[];
}

export type PageInfo = Omit<PaginatedResponse<Record<string, any>>, 'items'>;

const URL_REGEX = /([http].+)(>)/gm;

const findUrl = (links: string[], type: 'next' | 'prev' | 'last') => {
    const url = links.find((link) => link.includes(type));

    return extractUrl(url);
};

const extractUrl = (str?: string): string | undefined => {
    if (!str) {
        return undefined;
    }

    let m;

    while ((m = URL_REGEX.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === URL_REGEX.lastIndex) {
            URL_REGEX.lastIndex++;
        }

        return m[1];
    }

    return undefined;
};

export function buildPaginatedResponse<T>(response: AxiosResponse<T> | void) {
    const count = response?.headers['x-total-count'];
    const linkHeader = response?.headers['link'];

    let prevUrl, nextUrl, lastUrl;

    if (linkHeader) {
        const arr = linkHeader.split(',');
        prevUrl = findUrl(arr, 'prev');
        nextUrl = findUrl(arr, 'next');
        lastUrl = findUrl(arr, 'last');
    }

    return {
        totalItems: Number(count),
        prevUrl: prevUrl,
        nextUrl: nextUrl,
        lastUrl: lastUrl,
        items: response?.data || [],
    };
}

export default function useGenericCommonApi<T>(baseUrl: string) {
    const { showAlert } = useAlert();

    return {
        /**
         * Fetch item form database.
         *
         * @param url the URL to fetch
         * @param page the page
         * @param size number of items to get
         * @param eagerload prefetched the sub elements.
         * @returns PaginatedResponse
         */
        getAll: async (url: string, eagerload = false): Promise<PaginatedResponse<T>> => {
            const response = await axios
                .get<T[]>(
                    url + (eagerload ? `${url.indexOf('?') >= 0 ? '&' : ''}eagerload=true` : ''),
                )
                .catch((error) => {
                    showAlert(
                        'Erreur',
                        'Une erreur est survenue lors du chargement des informations',
                        'error',
                    );
                    console.error(`Fail to get items from DB`, error);
                });

            return buildPaginatedResponse(response);
        },
        create: async (
            data: T,
            callback: (response: T) => void,
            onError: (() => void) | undefined = undefined,
        ) => {
            return axios
                .post<T>(baseUrl, data)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error: AxiosError) => {
                    if (onError) {
                        onError();
                    }
                    showAlert(
                        'Erreur !',
                        (error?.response?.data as any)['detail'] ||
                            'La création a échoué, vérifier vos saisies !',
                        'error',
                    );
                    console.error(`Creation failed.`, error);
                });
        },

        update: async (id: ID, data: T, callback: (response: T) => void) => {
            return axios
                .put<T>(`${baseUrl}/${id}`, data)
                .then((response) => {
                    callback(response.data);
                })
                .catch((error: AxiosError) => {
                    showAlert(
                        'Erreur !',
                        (error?.response?.data as any)['detail'] ||
                            'La modification a échoué, vérifier vos saisies !',
                        'error',
                    );
                    console.error('Modification failed.', error);
                });
        },

        getById: async (id: ID, cb?: (value?: T) => void) => {
            const response = await axios.get<T>(`${baseUrl}/${id}`).catch((error: AxiosError) => {
                showAlert(
                    'Erreur !',
                    (error?.response?.data as any)['detail'] || 'Une erreur est survenue !',
                    'error',
                );
                console.error('Failed to get element from DB.', error);
            });

            if (cb) {
                cb(response?.data);
            }

            return response?.data;
        },

        deleteById: async (id: ID, callback: (response: any) => void) => {
            axios
                .delete(`${baseUrl}/${id}`)
                .then((response: any) => {
                    callback(response);
                })
                .catch((error: AxiosError) => {
                    showAlert(
                        'Erreur !',
                        (error?.response?.data as any)['detail'] || 'La suppression a échoué !',
                        'error',
                    );
                    console.error('Deletion failed.', error);
                });
        },
    };
}
