import { ReactElement } from 'react';
import { Navigate } from 'react-router';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE } from 'src/constants/cookie-constant';
import { useAuthenticated } from '../contexts/auth-context';
import Spinner from '../views/spinner/Spinner';

export const ProtectedRoute = ({
    children,
    allowedRoles,
}: {
    children: ReactElement;
    allowedRoles?: string[];
}) => {
    const { user, hasSellerRole, hasManagerRole, authenticationState } = useAuthenticated();

    // useEffect(() => {}, []);

    if (!Cookies.get(ACCESS_TOKEN_COOKIE)) {
        return <Navigate to="/auth/login" replace />;
    }

    // Show spinner when the auth context is loading his state
    if (authenticationState === 'IN_PROGRESS' || authenticationState === 'IDLE' || !user) {
        return <Spinner />;
    }

    const authorized = allowedRoles?.some((role) =>
        user?.user.authorities.includes(role as string),
    );

    if (authorized) {
        // the else ensure that nothing is rendered after the redirection in the if instruction.
        return children;
    } else {
        const redirectRoute =
            hasSellerRole === 'AUTHORIZED' || hasManagerRole === 'AUTHORIZED'
                ? '/courses'
                : '/auth/404';

        return <Navigate to={redirectRoute} replace />;
    }
};
