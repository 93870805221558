import axios from 'axios';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE } from 'src/constants/cookie-constant';

const axiosServices = axios.create();

// interceptor for http
axiosServices.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services'),
);

// Add authorization token to the request before sending it
axios.interceptors.request.use(
    (request) => {
        const requestUrl = request.url;
        if (
            !requestUrl.includes('/auth/') &&
            !requestUrl.includes('/authenticate') &&
            !requestUrl.includes('/account')
        ) {
            const headers = {
                ...request.headers,
                Authorization: `Bearer ${Cookies.get(ACCESS_TOKEN_COOKIE)}`,
            };

            return {
                ...request,
                headers: headers,
            };
        }

        return request;
    },
    (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services'),
);
export default axiosServices;
