import mock from '../mock';
import user1 from 'src/assets/images/profile/user-1.jpg';
import user2 from 'src/assets/images/profile/user-2.jpg';
import user3 from 'src/assets/images/profile/user-3.jpg';
import user4 from 'src/assets/images/profile/user-4.jpg';
import user5 from 'src/assets/images/profile/user-5.jpg';
import { Chance } from 'chance';
import { TicketType } from 'src/types/apps/ticket';

const chance = new Chance();

const TicketData: TicketType[] = [
    {
        Id: 1,
        ticketTitle: 'Sed ut perspiciatis unde omnis iste',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'error',
        thumb: user1,
        AgentName: 'Liam',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 2,
        ticketTitle: 'Consequuntur magni dolores eos qui ratione',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Pending',
        Label: 'warning',
        thumb: user2,
        AgentName: 'Steve',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 3,
        ticketTitle: 'Exercitationem ullam corporis',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Open',
        Label: 'success',
        thumb: user3,
        AgentName: 'Jack',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 4,
        ticketTitle: 'Sed ut perspiciatis unde omnis iste',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'error',
        thumb: user4,
        AgentName: 'Steve',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 5,
        ticketTitle: 'Exercitationem ullam corporis',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'error',
        thumb: user5,
        AgentName: 'Liam',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 6,
        ticketTitle: 'Consequuntur magni dolores eos qui ratione',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Pending',
        Label: 'warning',
        thumb: user1,
        AgentName: 'Jack',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 7,
        ticketTitle: 'Sed ut perspiciatis unde omnis iste',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Open',
        Label: 'success',
        thumb: user2,
        AgentName: 'Steve',
        Date: chance.date(),
        deleted: false,
    },
    {
        Id: 8,
        ticketTitle: 'Consequuntur magni dolores eos qui ratione',
        ticketDescription:
            'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'error',
        thumb: user3,
        AgentName: 'John',
        Date: chance.date(),
        deleted: false,
    },
];

mock.onGet('/api/data/ticket/TicketData').reply(() => {
    return [200, TicketData];
});
export default TicketData;
