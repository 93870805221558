import React, { createContext, useContext, useEffect, useState } from 'react';
import useAppUserApi, { AppUser } from '../api/app-user-api';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN_COOKIE } from '../constants/cookie-constant';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../components/alert/AlertContext';
import useAccountApi, { KeyAndPassword } from '../api/account-api';
import { AuthToken, LoginForm } from '../api/types';
import { authenticate as authUser } from '../api/auth-api';

type AuthorityState = 'IDLE' | 'AUTHORIZED' | 'UNAUTHORIZED';
type AuthenticationState = 'IDLE' | 'AUTHENTICATED' | 'IN_PROGRESS';

interface AuthState {
    authenticationState: AuthenticationState;
    user?: AppUser;
    hasSellerRole: AuthorityState;
    hasAdminRole: AuthorityState;
    hasManagerRole: AuthorityState;
    hasDriverRole: AuthorityState;
    hasControllerRole: AuthorityState;
    authenticate: (data: LoginForm) => Promise<void>;
    logout: () => void;
    sendResetMail: (email: string) => void;
    reset: (email: string, password: string) => void;
}
const AuthContext = createContext<AuthState>({
    authenticate: (data) => new Promise<void>((resolve) => resolve()),
    authenticationState: 'IDLE',
    hasAdminRole: 'IDLE',
    hasControllerRole: 'IDLE',
    hasDriverRole: 'IDLE',
    hasManagerRole: 'IDLE',
    hasSellerRole: 'IDLE',
    logout: () => {
        return;
    },
    reset: (email: string, password: string) => {
        return;
    },
    sendResetMail: (email: string) => {
        return;
    },
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const { sendResetMail: sendMail, changePassword } = useAccountApi();
    const navigate = useNavigate();
    const { showAlert } = useAlert();

    const [user, setUser] = useState<AppUser>();
    const [authenticationState, setAuthenticationState] = useState<AuthenticationState>('IDLE');
    const [hasAdminRole, setHasAdminRole] = useState<AuthorityState>('IDLE');
    const [hasManagerRole, setHasManagerRole] = useState<AuthorityState>('IDLE');
    const [hasSellerRole, setHasSellerRole] = useState<AuthorityState>('IDLE');
    const [hasControllerRole, setHasControllerRole] = useState<AuthorityState>('IDLE');
    const [hasDriverRole, setHasDriverRole] = useState<AuthorityState>('IDLE');

    const { getAuthenticated } = useAppUserApi();

    /**
     * Set the context state when the user refresh the page.
     * It avoids to user being considered logged, but hasn't admin role.
     */
    useEffect(() => {
        if (Cookies.get(ACCESS_TOKEN_COOKIE)) {
            getAuthenticatedUser();
        }
    }, []);

    useEffect(() => {
        if (!user) {
            getAuthenticatedUser();
        }
    }, [user]);

    function getAuthenticatedUser() {
        setAuthenticationState('IN_PROGRESS');
        void getAuthenticated((appUser) => {
            const authorities = appUser?.user.authorities || [];
            if (appUser) {
                setUser(appUser);
                setHasAdminRole(authorizationState(authorities, 'ROLE_ADMIN'));
                setHasManagerRole(authorizationState(authorities, 'ROLE_MANAGER'));
                setHasSellerRole(authorizationState(authorities, 'ROLE_SELLER'));
                setHasControllerRole(authorizationState(authorities, 'ROLE_CONTROLLER'));
                setHasDriverRole(authorizationState(authorities, 'ROLE_DRIVER'));
            }
        }).finally(() => {
            setAuthenticationState('AUTHENTICATED');
        });
    }

    const authenticate = async (data: LoginForm) => {
        return authUser(data)
            .then((response) => {
                if (!response) {
                    showAlert('Erreur', 'Echec de la connection', 'error');

                    return;
                }
                const expires = new Date();
                if (data.rememberMe) {
                    expires.setMonth(expires.getMonth() + 12); // One year of retention
                } else {
                    expires.setMinutes(expires.getMinutes() + 24 * 60); // One day of retention
                }

                Cookies.set(ACCESS_TOKEN_COOKIE, (response as AuthToken).id_token, {
                    path: '/',
                    expires: expires,
                    sameSite: 'strict',
                });

                getAuthenticatedUser();
            })
            .finally(() => setAuthenticationState('AUTHENTICATED'));
    };

    const authorizationState = (authorities: string[], target: string): AuthorityState => {
        if (!authorities || !target) {
            return 'UNAUTHORIZED';
        }

        return authorities.indexOf(target) >= 0 ? 'AUTHORIZED' : 'UNAUTHORIZED';
    };

    const logout = () => {
        Cookies.remove(ACCESS_TOKEN_COOKIE, { path: '/' });
        navigate('/auth/login');
    };

    const sendResetMail = (email: string) => {
        if (!email) {
            showAlert(
                'Erreur',
                "L'email est requis pour rénitialiser votre mot de passe !",
                'error',
            );

            return;
        }
        sendMail(email).then(() => {
            showAlert(
                'Réussite',
                "Un mail de rénitialisation a été envoyé à l'adresse indiquée.",
                'success',
            );
            navigate('/');
        });
    };
    const reset = (key: string, newPassword: string) => {
        if (!key || !newPassword) {
            showAlert('Erreur', 'La clé et le mot de passe sont requis !', 'error');

            return;
        }
        const data: KeyAndPassword = {
            key: key,
            newPassword: newPassword,
        };
        changePassword(data).then(() => {
            showAlert(
                'Réussite',
                'Mot de passe changée, vous pouvez desormais vous connectez.',
                'success',
            );
        });
    };

    return (
        <AuthContext.Provider
            value={{
                authenticationState,
                user,
                hasAdminRole,
                hasManagerRole:
                    hasManagerRole === 'AUTHORIZED' || hasAdminRole === 'AUTHORIZED'
                        ? 'AUTHORIZED'
                        : hasManagerRole,
                hasSellerRole:
                    hasSellerRole === 'AUTHORIZED' || hasAdminRole === 'AUTHORIZED'
                        ? 'AUTHORIZED'
                        : hasSellerRole,
                hasControllerRole:
                    hasControllerRole === 'AUTHORIZED' || hasAdminRole === 'AUTHORIZED'
                        ? 'AUTHORIZED'
                        : hasControllerRole,
                hasDriverRole:
                    hasDriverRole === 'AUTHORIZED' || hasAdminRole === 'AUTHORIZED'
                        ? 'AUTHORIZED'
                        : hasDriverRole,
                logout,
                sendResetMail,
                reset,
                authenticate,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuthenticated = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('Authentication required !');
    }

    return context;
};
